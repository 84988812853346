package com.leakingcode.main.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import com.leakingcode.fikit.api.model.FinanceSummaryApiModel
import com.leakingcode.main.viewmodel.MainViewModel
import kotlin.math.roundToLong
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Tbody
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Thead
import org.jetbrains.compose.web.dom.Tr

@Composable
fun TickerPage(ticker: String) {
    val viewModel = MainViewModel()
    val scope = CoroutineScope(Dispatchers.Main)
    val viewState = MutableStateFlow<List<FinanceSummaryApiModel>?>(null)
    FinanceSummaryView(viewState)
    scope.launch {
        viewState.emit(viewModel.fetchSummary(ticker))
    }
}

@Composable
fun FinanceSummaryView(financeSummaries: MutableStateFlow<List<FinanceSummaryApiModel>?>) {
    financeSummaries.asStateFlow().collectAsState().value?.forEach { financeSummary: FinanceSummaryApiModel ->
        Li {
            Div(attrs = {
                classes("finance-summary")
            }) {
                Table(attrs = {
                    classes("table")
                }) {
                    Tbody {
                        headValueRow(
                            listOf(
                                Pair("Website", financeSummary.companyWebsite),
                                Pair("Sector", financeSummary.sector)
                            )
                        )

                        headValueRow(
                            listOf(
                                Pair("Current price", financeSummary.currentPrice.toString()),
                                Pair(
                                    "Free cash flow",
                                    financeSummary.freeCashFlow.toString().formatNumberWithSeparators()
                                )
                            )
                        )

                        headValueRow(
                            listOf(
                                Pair("Currency", financeSummary.currency),
                                Pair("Beta 5y", financeSummary.beta.formatRatio())
                            )
                        )

                        headValueRow(
                            listOf(
                                "Book Value per share" to financeSummary.bookValue.formatRatio(),
                                "Price to Book Ratio" to financeSummary.priceToBookRatio.formatRatio()
                            )
                        )

                        headValueRow(
                            listOf(
                                "Business value per share" to financeSummary.targetPrice.businessShareValue.toString(),
                                "Target business value per share" to financeSummary.targetPrice.targetSharePrice.toString()
                            )
                        )

                        headValueRow(
                            listOf(
                                "Years target scope" to financeSummary.targetPrice.yearsScope.toString(),
                                "Target business growth ratio" to financeSummary.targetPrice.growthRatio.formatPercentage()
                            )
                        )

                        Tr {
                            Th { Text("Ex Dividend Date") }
                            Td { Text(financeSummary.lastDividend.date) }

                            Th { Text("Ex Dividend Amount") }
                            Td { Text("${financeSummary.lastDividend.amount}") }
                        }

                        headValueRow(
                            listOf(
                                "Avg dividend last 5 years" to financeSummary.avg5YearsDividend.toString(),
                                "Trail one year dividend" to financeSummary.lastYearDividend.toString()
                            )
                        )

                        headValueRow(
                            listOf(
                                "Payout ratio" to (financeSummary.lastDividend.amount
                                    .times(financeSummary.numberOfShares).div(financeSummary.freeCashFlow)
                                        ).formatPercentage(),
                                "Free Cash Flow to Cap" to financeSummary.freeCashFlow
                                    .div(financeSummary.capitalization).formatPercentage()
                            )
                        )

                        Tr {
                            Th { Text("Yield ex dividend over Book Value") }
                            Td { Text(financeSummary.lastDividend.yieldBookValue.formatPercentage()) }

                            Th { Text("Yield ex dividend over Stock Price") }
                            Td { Text(financeSummary.lastDividend.yieldStockPrice.formatPercentage()) }
                        }

                        Tr {
                            Th { Text("Number of Shares") }
                            Td { Text(financeSummary.numberOfShares.toString().formatNumberWithSeparators()) }

                            Th { Text("Capitalization") }
                            Td {
                                Span(attrs = {
                                    classes("bignumber")
                                }) {
                                    Text(financeSummary.capitalization.toString().formatNumberWithSeparators())
                                }
                            }
                        }

                        Tr {
                            Th { Text("Held Percent Insiders") }
                            Td { Text(financeSummary.heldPercentInsiders.formatPercentage()) }

                            Th { Text("Shares Short") }
                            Td { Text(financeSummary.sharesShort.toString().formatNumberWithSeparators()) }
                        }

                        Tr {
                            Th { Text("Profit Margins") }
                            Td { Text(financeSummary.profitMargins.formatPercentage()) }

                            Th { Text("PER") }
                            Td { Text(financeSummary.perRatio.formatRatio()) }
                        }
                        Tr {
                            Th { Text("PEG") }
                            Td { Text(financeSummary.pegRatio.formatRatio()) }

                            Th { Text("Revenue per share") }
                            Td { Text(financeSummary.revenuePerShare.toString()) }

                        }

                        headValueRow(
                            listOf(
                                "Growth earnings rate YoY quarterly" to financeSummary.growthEarningsYoYQ.formatPercentage(),
                                "Growth earnings estimate 5 years" to financeSummary.growthEarnings5Y.formatPercentage()
                            )
                        )
                    }
                }
                BalanceSheetView(financeSummary)
                EarningsView(financeSummary)
            }
        }
    }
}

@Composable
private fun BalanceSheetView(financeSummary: FinanceSummaryApiModel) {
    Div(attrs = {
        classes("sheet")
    }) {
        Table(attrs = {
            classes("table")
        }) {
            Thead {
                Tr {
                    Th { Text("Date") }
                    Th { Text("Assets") }
                    Th { Text("Liabilities") }
                    Th { Text("Net Worth") }
                    Th { Text("Net Ratio") }
                    Th { Text("Lia Ratio") }
                    Th { Text("Cash") }
                    Th { Text("Stockholder Equity") }
                    Th { Text("Intangible Assets") }
                }
            }
            Tbody {
                financeSummary.balanceSheet.forEach { balanceSheet ->
                    Tr {
                        Td { Text(balanceSheet.date.formatInstant()) }
                        Td { Text(balanceSheet.assets.toString().formatNumberWithSeparators()) }
                        Td { Text(balanceSheet.liabilities.toString().formatNumberWithSeparators()) }
                        Td { Text(balanceSheet.netWorth.toString().formatNumberWithSeparators()) }
                        Td { Text(balanceSheet.netRatio.formatPercentage()) }
                        Td { Text(balanceSheet.liaRatio.formatPercentage()) }
                        Td { Text(balanceSheet.cash.toString().formatNumberWithSeparators()) }
                        Td { Text(balanceSheet.stockHolderEquity.toString().formatNumberWithSeparators()) }
                        Td { Text(balanceSheet.intangibleAssets.toString().formatNumberWithSeparators()) }
                    }
                }
            }
        }
    }
}

@Composable
private fun EarningsView(financeSummary: FinanceSummaryApiModel) {
    Div(attrs = { classes("sheet") }) {
        Table(attrs = { classes("table") }) {
            Thead {
                Tr {
                    Th { Text("Year") }
                    Th { Text("Earnings") }
                    Th { Text("Profit margins") }
                }
            }
            Tbody {
                financeSummary.earnings
                    .sortedByDescending {
                        it.year
                    }
                    .forEach { earnings ->
                        Tr {
                            Td { Text("${earnings.year}") }
                            Td { Text(earnings.earnings.toString().formatNumberWithSeparators()) }
                            Td { Text(earnings.profitMargins.formatPercentage()) }
                        }
                    }
            }
        }
    }
}

@Composable
private fun headValueRow(headValues: List<Pair<String, String>>) {
    Tr {
        headValues.map {
            Th { Text(it.first) }
            Td { Text(it.second) }
        }
    }
}

private fun String.formatNumberWithSeparators(): String {
    val formattedString = StringBuilder()
    val number = toDouble().roundToLong().toString()
    var count = 0
    for (i in number.length - 1 downTo 0) {
        formattedString.insert(0, number[i])
        count++
        if (count == 3 && i != 0) {
            formattedString.insert(0, ".")
            count = 0
        }
    }

    return formattedString.toString()
}


private fun Float.formatPercentage(): String = "${times(100)}".substring(IntRange(0, 4)) + "%"

private fun Double.formatPercentage(): String = toFloat().formatPercentage()

private fun Float.formatRatio(): String = toString().slice(IntRange(0, 3))

private fun Double.formatRatio(): String = toFloat().formatRatio()

private fun Instant.formatInstant(): String {
    return toString().replaceAfter("T", "").replace("T", "")
}
