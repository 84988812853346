package com.leakingcode.fikit.api

import com.leakingcode.fikit.api.model.FinanceSummaryApiModel
import com.leakingcode.fikit.api.model.TickerApiModel
import com.leakingcode.fikit.api.model.TickersApiModel
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.logging.DEFAULT
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import io.ktor.serialization.kotlinx.json.json

class ClientApi {

    val host = "https://fikit-web-api-fyejjhvmka-ew.a.run.app/api"
//    val host = "http://localhost:8080/api"

    private val httpClient = HttpClient {
        install(ContentNegotiation) {
            json()
        }
        install(Logging) {
            logger = Logger.DEFAULT
            level = LogLevel.ALL
        }
    }

    suspend fun financeSummary(tickerCode: String): List<FinanceSummaryApiModel> {
        return httpClient.get(
            "$host/summary?ticker=$tickerCode"
        ).body<List<FinanceSummaryApiModel>>()
    }

    suspend fun addTicker(tickerCode: String): HttpStatusCode {
        return httpClient.post("$host/ticker") {
            contentType(ContentType.Application.Json)
            setBody(TickerApiModel(code = tickerCode))
        }.status
    }

    suspend fun getTicker(): TickersApiModel {
        return httpClient.get("$host/ticker").body<TickersApiModel>()
    }
}
