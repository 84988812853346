package com.leakingcode.fikit.api.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class FinanceSummaryApiModel(
    val companyWebsite: String,
    val sector: String,
    val currency: String,
    val beta: Double,
    val bookValue: Double,
    val priceToBookRatio: Double,
    val lastDividend: Dividend,
    val avg5YearsDividend: Double,
    val lastYearDividend: Double,
    val numberOfShares: Long,
    val capitalization: Double,
    val heldPercentInsiders: Double,
    val sharesShort: Long,
    val balanceSheet: List<BalanceSheet>,
    val profitMargins: Double,
    val earnings: List<Earnings>,
    val pegRatio: Double,
    val yield: Double,
    val revenuePerShare: Double,
    val growthEarningsYoYQ: Double,
    val growthEarnings5Y: Double,
    val perRatio: Double,
    val currentPrice: Double,
    val freeCashFlow: Double,
    val targetPrice: TargetPrice
//    val totalAssets: Long,
//    val totalLiabilities: Long,
) {

    @Serializable
    data class TargetPrice(
        val targetSharePrice: Double,
        val businessShareValue: Double,
        val yearsScope: Int,
        val growthRatio: Double,
    )

    @Serializable
    data class Dividend(
        val amount: Double,
        val date: String,
        val yieldBookValue: Double,
        val yieldStockPrice: Double,
    )

    @Serializable
    data class BalanceSheet(
        val date: Instant,
        val assets: Long,
        val liabilities: Long,
        val netWorth: Long,
        val netRatio: Float = netWorth.div(assets.toFloat()),
        val liaRatio: Float = liabilities.div(assets.toFloat()),
        val cash: Long,
        val stockHolderEquity: Long,
        val intangibleAssets: Long,
    )

    @Serializable
    data class Earnings(
        val year: Int,
        val earnings: Long,
        val profitMargins: Float
    )
}
