package com.leakingcode.main.viewmodel

import com.leakingcode.fikit.api.ClientApi
import com.leakingcode.fikit.api.model.FinanceSummaryApiModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch

class MainViewModel {

    private val scope = CoroutineScope(Dispatchers.Main)
    val viewState = MutableStateFlow<List<TickerModel>?>(null)

    data class TickerModel(val name: String)

    private val clientApi = ClientApi()

    fun fetchTickers() {
        scope.launch {
            clientApi.getTicker().tickers.map {
                TickerModel(it.code)
            }.let {
                viewState.emit(it)
            }
        }
    }

    fun addTicker(it: TickerModel) {
        scope.launch {
//            clientApi.addTicker(it.name)
            viewState.emit(viewState.value?.toMutableList()?.apply {
                add(it)
            })
        }
    }

    suspend fun fetchSummary(ticker: String): List<FinanceSummaryApiModel> {
        return clientApi.financeSummary(ticker)
    }
}
