package com.leakingcode.main.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.toMutableStateList
import com.leakingcode.main.components.trDraggable
import com.leakingcode.main.viewmodel.MainViewModel
import kotlinx.coroutines.flow.asStateFlow
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Tr

@Composable
fun homePage() {
    val viewModel = remember { MainViewModel() }
    viewModel.fetchTickers()
    tickersPage(viewModel)
}

@Composable
private fun tickersPage(viewModel: MainViewModel) {
    val tickers = viewModel.viewState.asStateFlow().collectAsState().value
    val newTicker = remember { mutableStateOf("") }
    val addTicker = {
        if (newTicker.value.isNotBlank()) {
            MainViewModel.TickerModel(newTicker.value).let {
                viewModel.addTicker(it)
            }
            newTicker.value = ""
        }
    }

    Table {
        Tr {
            Th {
                Text("Ticker")
            }
        }
        tickers?.forEachIndexed { index, ticker ->
            trDraggable(index, tickers.toMutableStateList()) {
                Td { A(href = "?ticker=${ticker.name}") { Text(ticker.name) } }
            }
        }
    }

    Input(type = InputType.Text) {
        placeholder("Ticker")
        value(newTicker.value)
        onInput { event ->
            newTicker.value = event.value
        }

        onKeyDown {
            if (it.code == "Enter") {
                addTicker()
            }
        }
    }

    Button(
        attrs = {
            if (newTicker.value.isBlank()) disabled()
            onClick {
                addTicker()
            }
        }
    ) {
        Text("Add ticker summary")
    }
}
