package com.leakingcode.main.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.snapshots.SnapshotStateList
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Tr
import org.w3c.dom.HTMLTableRowElement


@Composable
fun <T> trDraggable(
    index: Int,
    stateList: SnapshotStateList<T>,
    content: ContentBuilder<HTMLTableRowElement>? = null
) {
    val draggedRowIndex: MutableState<Int> = mutableStateOf(-1)
    Tr(attrs = {
        draggable(Draggable.True)
        if (index == draggedRowIndex.value) {
            classes("dragged")
        }
        onDragStart {
            draggedRowIndex.value = index
        }
        onDragOver {
            it.preventDefault()
        }
        onDrop {
            it.preventDefault()
            val dragged = stateList[draggedRowIndex.value]
            stateList.removeAt(draggedRowIndex.value)
            stateList.add(index, dragged)
            draggedRowIndex.value = -1
        }


    }, content)
}
