package com.leakingcode.main

import com.leakingcode.main.pages.TickerPage
import com.leakingcode.main.pages.homePage
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.url.URLSearchParams

fun main() {
    renderComposable(rootElementId = "root") {
        when (val page = getCurrentPage()) {
            Page.HomePage -> homePage()
            is Page.TickerPage -> TickerPage(page.code)
        }
    }
}

private const val paramTicker = "ticker"
private fun getCurrentPage(): Page {
    val urlParams = URLSearchParams(window.location.search)
    return if (urlParams.get(paramTicker) != null) {
        return Page.TickerPage(checkNotNull(urlParams.get(paramTicker)))
    } else {
        Page.HomePage
    }
}

sealed class Page {
    object HomePage : Page()
    data class TickerPage(val code: String) : Page()

}
